import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import zhCN from 'antd/locale/zh_CN'
import 'dayjs/locale/zh-cn'

import '@/assets/css/index.css'
import theme from '@/assets/theme'
import store from '@/store'
import router from './router'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <ConfigProvider locale={zhCN} theme={{
    token: {
      // colorPrimary: '#486da0'
      colorPrimary: '#486da0'
    }
  }}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <RouterProvider router={router} />
        </Suspense>
      </Provider>
    </ThemeProvider>
  </ConfigProvider>
  // </React.StrictMode>
)

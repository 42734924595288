import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import type { RootStore } from '@/hooks/redux-tookit'

import { fetchArea } from '@/services/app'

export const getArea = createAsyncThunk<void, Record<string, any>, { state: RootStore }>(
  'getArea',
  async (queryParams, { dispatch }) => {
    const result = await fetchArea(queryParams)
    dispatch(setCityAreaList(result.data.areaListTwoLevel))
    dispatch(
      setTopArea({
        name: result.data.areaList[0].areaName,
        code: result.data.areaList[0].areaCode
      })
    )
    const areaStr = localStorage.getItem('currentArea')
    let curArea: {name: string, code: string}={
      name: '',
      code: ''
    }
    let flag = true
    if(areaStr){
      const areaJson = JSON.parse(areaStr)
      const item = result.data.areaListTwoLevel.find( (obj: { areaCode: any }) => obj.areaCode == areaJson.code)
      if(item){
        flag = false
        curArea=areaJson
      }
    }
    if(flag){
      curArea = {
        name: result.data.areaList[0].areaName,
        code: result.data.areaList[0].areaCode
      }
    }
    const rst = await fetchArea({areaCode: curArea.code})
    dispatch(setAreaList(rst.data.areaListTwoLevel))
    dispatch(setCurrentArea(curArea))
  }
)

const appSlice = createSlice({
  name: 'appSlice',
  initialState: {
    currentArea: {
      name: '',
      code: ''
    },
    topArea:{
      name: '',
      code: ''
    },
    cityAreaList: [],
    areaList: []
  },
  reducers: {
    setAreaList(state, { payload }) {
      state.areaList = payload
    },
    setTopArea(state, { payload }: PayloadAction<{ name: string; code: string }>) {
      state.topArea = payload
    },
    setCurrentArea(state, { payload }: PayloadAction<{ name: string; code: string }>) {
      state.currentArea = payload
      localStorage.setItem('currentArea', JSON.stringify(state.currentArea))
    },
    setCityAreaList(state, { payload }) {
      state.cityAreaList = payload
    },
  }
})

export const { setAreaList, setTopArea, setCurrentArea, setCityAreaList } = appSlice.actions
export default appSlice.reducer

import request from '@/services'

export default async function AuthLoader() {
  const userInfoStr = localStorage.getItem('user-info')
  // 判断是否登录过
  if (!userInfoStr) throw new Error('当前用户未登录, 请前往登录页面')
  const userInfo = JSON.parse(userInfoStr)
  // 请求当前用户是否是 VIP
  let isVIP = false
  // try {
  const result = await request.getAction({
    url: '/bigdataapi/userinfo/vipCheck',
    params: { id: userInfo.id }
  })
  if (!result.data) throw new Error('当前用户不是VIP,请前往开通页面')
  isVIP = result.data
  return true
}

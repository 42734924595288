import React, { lazy } from 'react'
import { Navigate, createBrowserRouter } from 'react-router-dom'

import request from '@/services'
import AuthLoader from '@/loaders/AuthLoader'
import LoginLoader from '@/loaders/LoginLoader'

const App = lazy(() => import('@/App'))
const Home = lazy(() => import('@/views/Home'))
const NotFound = lazy(() => import('@/views/NotFound'))
const Supplier = lazy(() => import('@/views/Supplier'))
const Purchase = lazy(() => import('@/views/Purchase'))
const Brand = lazy(() => import('@/views/Brand'))
const Product = lazy(() => import('@/views/Product'))
const Category = lazy(() => import('@/views/Category'))
const Area = lazy(() => import('@/views/Area'))
const ComprehensiveQuery = lazy(() => import('@/views/ComprehensiveQuery'))
const DetailLayOut = lazy(() => import('@/layout/src/DetailLayOut'))
const Detail = lazy(() => import('@/views/Detail/views/Detail'))
const List = lazy(() => import('@/views/Detail/views/List'))
const Notice = lazy(() => import('@/views/Detail/views/Notice'))
const DataReportLayOut = lazy(() => import('@/layout/src/DataReportLayOut'))
const DataReportIndex = lazy(() => import('@/views/DataReport/views/DataReportIndex'))
const DataReportList = lazy(() => import('@/views/DataReport/views/DataReportList'))
const User = lazy(() => import('@/views/User'))
const Login = lazy(() => import('@/views/Login'))
const Authority = lazy(() => import('@/views/Authority'))

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Navigate to="/home" />
      },
      {
        index: true,
        path: '/home',
        element: <Home />
      },
      {
        path: '/supplier',
        element: <Supplier />
      },
      {
        path: '/purchase',
        element: <Purchase />
      },
      {
        path: '/brand',
        element: <Brand />
      },
      {
        path: '/product',
        element: <Product />
      },
      {
        path: '/category',
        element: <Category />
      },
      {
        path: '/area',
        element: <Area />
      },
      {
        path: '/dataReport',
        element: <DataReportLayOut />,
        children: [
          {
            path: 'index',
            element: <DataReportIndex />
          },
          {
            path: ':type/list/:key',
            loader: AuthLoader,
            errorElement: <Navigate to="/authority" />,
            element: <DataReportList />
          }
        ]
      },
      {
        // 综合 搜索页面
        path: '/comprehensiveQuery/:type?/:searchText?',
        element: <ComprehensiveQuery />
      },
      {
        // 用户
        path: '/user/:type?',
        loader: LoginLoader,
        errorElement: <Navigate to="/authority" />,
        element: <User />
      },
      {
        path: '/detail',
        element: <DetailLayOut />,

        children: [
          {
            path: ':type',
            loader: AuthLoader,
            errorElement: <Navigate to="/authority" />,
            element: <Detail />
          },
          {
            path: 'list/:type',
            loader: AuthLoader,
            errorElement: <Navigate to="/authority" />,
            element: <List />
          },
          {
            path: 'notice/:id',
            loader: AuthLoader,
            errorElement: <Navigate to="/authority" />,
            element: <Notice />
          }
        ]
      },
      {
        // 权限
        path: '/authority',
        element: <Authority />
      }
    ]
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '*',
    element: <NotFound />
  }
])

export default routes

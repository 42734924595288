import { configureStore } from '@reduxjs/toolkit'

import {
  homeReducer,
  appReducer,
  detailReducer,
  dataReportIndexReducer,
  dataReportListReducer,
  subscribeReducer
} from './features'

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    home: homeReducer,
    app: appReducer,
    detail: detailReducer,
    dataReportIndex: dataReportIndexReducer,
    dataReportList: dataReportListReducer,
    subscribe: subscribeReducer
  }
})

export default store

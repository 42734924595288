import JRequest from './request'
import { BASE_URl, TIME_OUT } from './config'

export default new JRequest({
  baseURL: BASE_URl,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor(config) {
      const useInfo = localStorage.getItem('user-info')
      if (useInfo) {
        config.headers = {
          ...config.headers,
          'third-session': JSON.parse(useInfo)?.thirdSession
        }
      }
      return config
    },
    responseInterceptor(res) {
      return res
    }
  }
})

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import type { RootStore } from '@/hooks/redux-tookit'

import {
  fetchHomeDataStatistics,
  fetchHomeNotice,
  fetchHomeProportion,
  fetchMarketContract,
  fetchRankingList
} from '@/services/home'

export const getHomeDataStatistics = createAsyncThunk<void, any, { state: RootStore }>(
  'getHomeDataStatistics',
  async (queryParams, { dispatch }) => {
    const result = await fetchHomeDataStatistics(queryParams)
    dispatch(setHomeDataStatistics({ ...result.data }))
  }
)

export const getHomeNotice = createAsyncThunk<void, any, { state: RootStore }>(
  'getHomeNotice',
  async (queryParams, { dispatch }) => {
    const result = await fetchHomeNotice(queryParams)
    dispatch(
      setHomeNotice({ data: result.data.records, type: queryParams.marketContractHomeDTO.type })
    )
  }
)

export const getMarketContract = createAsyncThunk<void, any, { state: RootStore }>(
  'getMarketContract',
  async (queryParams, { dispatch }) => {
    const result = await fetchMarketContract(queryParams)
    result.data.length && dispatch(setHomeMarketContract([...result.data]))
  }
)

export const getMarketContractProportion = createAsyncThunk<void, any, { state: RootStore }>(
  'getMarketContractProportion',
  async (queryParams, { dispatch }) => {
    const result = await fetchHomeProportion(queryParams)
    dispatch(setHomeProportion({ data: result.data.records, type: queryParams.type }))
  }
)

export const getMarketContractRanking = createAsyncThunk<void, any, { state: RootStore }>(
  'getMarketContractRanking',
  async (queryParams, { dispatch }) => {
    const result = await fetchRankingList(queryParams)
    dispatch(setHomeRanking({ data: result.data.records, type: queryParams.type }))
  }
)

const homeSlice = createSlice({
  name: 'homeSlice',
  initialState: {
    // 数据统计
    homeDataStatistics: {
      numMarketContract: '0',
      sumMarketContractAmount: '0'
    },
    // 公告列表
    homeNotice: {
      list1: [], // 合同公告
      list2: [], // 采购意向公开
      list3: [] // 招标公告
    },
    // 趋势图
    homeMarketContract: [],
    // 数据占比
    homeProportion: {
      list1: [], // 地区
      list2: [] // 品类
    },
    // 排行列表
    homeRanking: {
      list1: [], // 产品
      list2: [], // 品牌
      list3: [] // 品类
    }
  },
  reducers: {
    setHomeDataStatistics(state, { payload }) {
      state.homeDataStatistics = payload
    },
    setHomeNotice(state, { payload }: PayloadAction<{ type: string; data: any }>) {
      if (payload.type === 'htgg') {
        state.homeNotice.list1 = payload.data
      } else if (payload.type === 'cgyxgg') {
        state.homeNotice.list2 = payload.data
      } else if (payload.type === 'cggg') {
        state.homeNotice.list3 = payload.data
      }
    },
    setHomeMarketContract(state, { payload }) {
      state.homeMarketContract = payload
    },
    setHomeProportion(state, { payload }: PayloadAction<{ type: string; data: any }>) {
      if (payload.type === '1') {
        state.homeProportion.list1 = payload.data
      } else {
        state.homeProportion.list2 = payload.data
      }
    },
    setHomeRanking(state, { payload }: PayloadAction<{ type: string; data: any }>) {
      switch (payload.type) {
        case '2':
          state.homeRanking.list3 = payload.data
          break
        case '3':
          state.homeRanking.list1 = payload.data
          break
        case '4':
          state.homeRanking.list2 = payload.data
          break
      }
    }
  }
})

export const {
  setHomeDataStatistics,
  setHomeNotice,
  setHomeMarketContract,
  setHomeProportion,
  setHomeRanking
} = homeSlice.actions
export default homeSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

type InitialState = {
  step: number
  detail: Record<string, any>
}

const initialState: InitialState = {
  step: 1,
  detail: {}
}

const subscribeSlice = createSlice({
  name: 'subscribeSlice',
  initialState,
  reducers: {
    setStep(state, { payload }) {
      state.step = payload
    },
    setDetail(state, { payload }) {
      state.detail = payload
    }
  }
})

export const { setStep, setDetail } = subscribeSlice.actions
export default subscribeSlice.reducer

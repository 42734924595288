import request from '../index'

enum API {
  areaDataStatistics = '/getDataStatistics', // 当前地级市或者福建省的数据统计
  notice = '/getNotice', // 公告列表
  marketContract = '/getMarketContractGroupByDate', // 采购趋势图
  proportion = '/getMarketContractProportion', // 数据占比
  ranking = '/getMarketContractRanking', // 排行列表
  categroy = '/tree', // 产品品类
  totalStatistics = '/getDataStatisticsDTO', // 总数数据统计
  noticeDetailList = '/getNoticeDetail' // 合同明细列表
}
const preFix = '/bigdataapi/marketcontract'

export type QueryParams = {
  morpid?: string
  areaCode: string
  type?: '1' | '2' | '3' | '4' | '5' | '6'
  beginTime?: string
  endTime?: string
  marketContractHomeDTO?: {
    type?: string
    announcementDate?: string
    areaName?: string
    catalogName?: string
    createDay?: string
    goodsBrand?: string
    goodsCount?: string
    goodsName?: string
    numMarketContract?: string
    purchaseOrgan?: string
    sumAmount?: string
    supplier?: string
  }
  typeByParam?: string
  pageSize?: number
  pageNo?: number
}

/**
 * 获取首页统计
 * @param queryParams 查询参数
 * type
 * 1整个福州市包含下级区域的
 * 2单个小区域
 * @returns
 */
export const fetchHomeDataStatistics = (queryParams: QueryParams) => {
  return request.postAction({
    url: preFix + API.areaDataStatistics,
    data: { ...queryParams }
  })
}

/**
 * 获取公告列表
 * @param queryParams 查询参数
 * type
 * 1网上超市合同公告
 * 2采购意向公告
 * 3网上超市合同公告和采购意向公告
 * @returns promise
 */
export const fetchHomeNotice = (queryParams: QueryParams) => {
  return request.postAction({
    url: preFix + API.notice,
    data: { ...queryParams }
  })
}

// body
// params

/**
 * 获取首页趋势图
 * @param queryParams
 * type
 * 1日
 * 2月
 * 3年
 * @returns promise
 */
export const fetchMarketContract = (queryParams: QueryParams) => {
  return request.postAction({
    url: preFix + API.marketContract,
    data: { ...queryParams }
  })
}

/**
 * 获取数据占比
 * @param queryParams
 * type:
 * 1城市占比area_name ;
 * 2品类占比catalog_name;
 * 3产品占比goods_name;
 * 4品牌占比goods_brand;
 * 5采购单位占比purchase_organ;
 * 6供应商占比supplier
 * @returns promise
 */
export const fetchHomeProportion = (queryParams: QueryParams) => {
  return request.postAction({
    url: preFix + API.proportion,
    data: { ...queryParams }
  })
}

/**
 * 获取排行列表
 * @param queryParams
 * type:
 * 1城市排行area_name ;
 * 2品类排行catalog_name;
 * 3产品排行goods_name;
 * 4品牌排行goods_brand;
 * 5采购单位排行purchase_organ;
 * 6供应商排行supplier
 * @returns promise
 */
export const fetchRankingList = (queryParams: QueryParams) => {
  return request.postAction({
    url: preFix + API.ranking,
    data: { ...queryParams }
  })
}

/**
 * 获取产品品类
 * @param queryParams
 * @returns
 */
export const fetchCategroy = (queryParams: QueryParams) => {
  return request.postAction({
    url: preFix + API.categroy,
    data: { ...queryParams }
  })
}

/**
 * 获取总数统计
 * @param queryParams
 * @returns promise
 */
export const fetchTotalStatistics = (queryParams: QueryParams) => {
  return request.postAction({
    url: preFix + API.totalStatistics,
    data: { ...queryParams }
  })
}

/**
 * 获取合同明细列表
 * @param queryParams
 * @returns
 */
export const fetchNoticeDetailList = (queryParams: Partial<QueryParams>) => {
  return request.postAction({
    url: preFix + API.noticeDetailList,
    data: { ...queryParams }
  })
}

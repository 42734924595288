import { DefaultTheme } from 'styled-components'

// 项目主题颜色
const theme: DefaultTheme = {
  // 边框
  border: {
    radius: '8px' // 边框圆角
  },
  color: {
    first: '#333', // 主色调
    second: '#3c3d3d', // 标题
    high: '#486da0', // 高亮
    bg: '#ffffff', // 背景颜色
    bg2: '#f1f4ff', // 背景颜色2
    bg3: '#486da0' // 背景颜色3
  },
  font: {
    size: ''
  }
}

export default theme

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { RootStore } from '@/hooks/redux-tookit'

import {
  QueryParams,
  fetchHomeProportion,
  fetchMarketContract,
  fetchRankingList,
  fetchTotalStatistics
} from '@/services/home'

export const fetchJuneMarketContract = createAsyncThunk<
  void,
  QueryParams,
  {
    state: RootStore
  }
>('fetch', async (queryParams, { dispatch }) => {
  const result = await fetchMarketContract(queryParams)
  dispatch(setPurchaseData(result.data))
})

export const fetchJuneAreaProportion = createAsyncThunk<
  void,
  QueryParams,
  {
    state: RootStore
  }
>('fetch', async (queryParams, { dispatch, getState }) => {
  const state = getState()
  const areaList = state.app.areaList
  const promises = areaList.map(
    async (item: any) => await fetchTotalStatistics({ ...queryParams, areaCode: item.areaCode })
  )
  const allResult = await Promise.all(promises)
  const data = allResult.map((item, index) => {
    const value = item.data.records[0]?.sumAmount || 0
    return { name: (areaList[index] as any).areaName, value }
  })
  dispatch(setAreaProportionData(data))
})

export const fetchJuneCategoryProportion = createAsyncThunk<
  void,
  QueryParams,
  {
    state: RootStore
  }
>('fetch', async (queryParams, { dispatch }) => {
  const result = await fetchHomeProportion(queryParams)
  dispatch(setCategoryProportionData(result.data.records))
})

export const fetchJuneRanking = createAsyncThunk<
  void,
  QueryParams,
  {
    state: RootStore
  }
>('fetch', async (queryParams, { dispatch }) => {
  const result = await fetchRankingList(queryParams)
  dispatch(setRankingList({ type: queryParams.type, data: result.data.records }))
})

const initialState: {
  purchaseData: any[]
  areaProportionData: any[]
  categoryProportionData: any[]
  ranking: {
    list1: any[]
    list2: any[]
    list3: any[]
    list4: any[]
    list5: any[]
  }
} = {
  purchaseData: [],
  areaProportionData: [],
  categoryProportionData: [],
  ranking: {
    list1: [], // 产品
    list2: [], // 品牌
    list3: [], // 品类
    list4: [], // 供应商
    list5: [] // 采购单位
  }
}

const dataReportIndexSlice = createSlice({
  name: 'dataReportSlice',
  initialState,
  reducers: {
    setPurchaseData(state, { payload }) {
      state.purchaseData = payload
    },
    setAreaProportionData(state, { payload }) {
      state.areaProportionData = payload
    },
    setCategoryProportionData(state, { payload }) {
      state.categoryProportionData = payload
    },
    setRankingList(state, { payload }: PayloadAction<{ type: QueryParams['type']; data: any[] }>) {
      switch (payload.type) {
        case '3':
          state.ranking.list1 = payload.data
          break
        case '4':
          state.ranking.list2 = payload.data
          break
        case '2':
          state.ranking.list3 = payload.data
          break
        case '6':
          state.ranking.list4 = payload.data
          break
        case '5':
          state.ranking.list5 = payload.data
          break
      }
    }
  }
})

export const { setPurchaseData, setAreaProportionData, setCategoryProportionData, setRankingList } =
  dataReportIndexSlice.actions
export default dataReportIndexSlice.reducer

import axios from 'axios'
import { AxiosInstance } from 'axios'
import { JAxiosRequestConfig } from './type'

class JRequest {
  axiosInstance: AxiosInstance

  constructor(config: JAxiosRequestConfig) {
    this.axiosInstance = axios.create(config)

    // 添加当前axiox实例的拦截器
    this.axiosInstance.interceptors.request.use(
      (config) => {
        return config
      },
      (error) => {
        return error
      }
    )

    this.axiosInstance.interceptors.response.use(
      (res) => {
        if (res.config.responseType === "blob") {
          return res
        } else {
          return res.data
        }
      },
      (error) => {
        return error
      }
    )

    // 添加所有JRequest实例都有的拦截器
    this.axiosInstance.interceptors.request.use(
      config.interceptors?.requestInterceptor,
      config.interceptors?.requestCatchInterceptor
    )

    this.axiosInstance.interceptors.response.use(
      config.interceptors?.responseInterceptor,
      config.interceptors?.responseCatchInterceptor
    )
  }

  request<T>(config: JAxiosRequestConfig<T>): Promise<T> {
    // 添加每个请求的拦截器
    if (config.interceptors?.requestInterceptor) {
      config = config.interceptors.requestInterceptor(config)
    }

    return new Promise<T>((resolve, reject) => {
      this.axiosInstance
        .request<any, T>(config)
        .then((res) => {
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getAction<T = any>(config: JAxiosRequestConfig<T>) {
    return this.request<T>({ ...config, method: 'GET' })
  }

  postAction<T = any>(config: JAxiosRequestConfig<T>) {
    return this.request<T>({ ...config, method: 'POST' })
  }

  deleteAction<T = any>(config: JAxiosRequestConfig<T>) {
    return this.request<T>({ ...config, method: 'DELETE' })
  }

  patchAction<T = any>(config: JAxiosRequestConfig<T>) {
    return this.request<T>({ ...config, method: 'PATCH' })
  }
}

export default JRequest

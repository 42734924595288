import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import type { RootStore } from '@/hooks/redux-tookit'
import { QueryParams, fetchCategroy, fetchRankingList, fetchTotalStatistics } from '@/services/home'

import { fetchHomeNotice, fetchMarketContract } from '@/services/home'
import { fetchArea } from '@/services/app'

const queue = [
  {
    key: 'getTotalStatistics',
    request: fetchTotalStatistics,
    getData: (result: any) => {
      const statistics = result.data.records?.[0]
      return [
        { number: `¥${statistics?.sumAmount || 0}`, text: '合同总数' },
        { number: `${statistics?.numMarketContract || 0}`, text: '合同笔数' },
        { number: `${statistics?.numGoodsCount || 0}`, text: '销售总数量' },
        { number: `${statistics?.numGoods || 0}`, text: '相关产品数' },
        { number: `${statistics?.numPurchaseOrgan || 0}`, text: '采购单位数量' },
        { number: `${statistics?.numCatalogName || 0}`, text: '品类数量' },
        { number: `${statistics?.numGoodsBrand || 0}`, text: '品牌数量' },
        { number: `${statistics?.numAreaName || 0}`, text: '相关城市' }
      ]
    },
    action: (dispatch: any, payload: any) => dispatch(setTotalStatistics(payload)),
    params: {
      type: '1'
    }
  },
  {
    key: 'getDetailNotice',
    request: fetchHomeNotice,
    getData: (result: any) => {
      return result.data.records.map((item: any) => ({
        ...item,
        key: item.id,
        leftContent: [
          `【${item.contractNo}】${item.contractName}`,
          `${item.purchaseOrgan}/${item.supplier}`
        ],
        centerContent: '￥' + item.amount,
        rightContent: item.announcementDate
      }))
    },
    action: (dispatch: any, payload: any) => dispatch(setNotice({ type: 'htgg', data: payload })),
    params: {
      type: '1',
      marketContractHomeDTO: {
        type: 'htgg'
      },
      pageNo: 1,
      pageSize: 5
    }
  },
  {
    key: 'getNotice1',
    request: fetchHomeNotice,
    getData: (result: any) => {
      return result.data.records.map((item: any) => ({
        ...item,
        key: item.id,
        leftContent: [item.title],
        rightContent: item.announcementDate
      }))
    },
    action: (dispatch: any, payload: any) => dispatch(setNotice({ type: 'cgyxgg', data: payload })),
    params: {
      type: '2',
      marketContractHomeDTO: {
        type: 'cgyxgg'
      },
      pageNo: 1,
      pageSize: 5
    }
  },
  {
    key: 'getNotice2',
    request: fetchHomeNotice,
    getData: (result: any) => {
      return result.data.records.map((item: any) => ({
        ...item,
        key: item.id,
        leftContent: [item.title],
        rightContent: item.announcementDate
      }))
    },
    action: (dispatch: any, payload: any) => dispatch(setNotice({ type: 'cggg', data: payload })),
    params: {
      type: '2',
      marketContractHomeDTO: {
        type: 'cggg'
      },
      pageNo: 1,
      pageSize: 5
    }
  },
  {
    key: 'getDetailMarketContract',
    request: fetchMarketContract,
    getData: (result: any) => {
      // 有数据就用数据的
      if (result.data.length) {
        return {
          xData: result.data.map((item: any) => item.createDay),
          sData: result.data.map((item: any) => item.sumAmount)
        }
      } else {
        // 没有数据就显示7日数据
        const today = dayjs()
        const xData: string[] = []
        const sData: number[] = []
        for (let i = 0; i < 7; i++) {
          xData[i] = today.subtract(i, 'day').format('YYYY-MM-DD')
          sData[i] = 0
        }
        return { xData, sData }
      }
    },
    action: (dispatch: any, payload: any) => dispatch(setMarketContract(payload)),
    params: {
      type: '1'
    }
  },
  {
    key: 'getDetailRanking1',
    request: fetchRankingList,
    getData: (result: any) => {
      return result.data.records.map((item: any, index: number) => ({
        ...item,
        key: index + '',
        leftContent: [item.purchaseOrgan],
        rightContent: '￥' + item.sumAmount
      }))
    },
    action: (dispatch: any, payload: any) => dispatch(setRankingList({ type: '5', data: payload })),
    params: {
      type: '5',
      pageSize: 5,
      pageNo: 1
    }
  },
  {
    key: 'getDetailRanking2',
    request: fetchRankingList,
    getData: (result: any) => {
      return result.data.records.map((item: any, index: number) => ({
        ...item,
        key: index + '',
        leftContent: [item.goodsBrand],
        rightContent: '￥' + item.sumAmount
      }))
    },
    action: (dispatch: any, payload: any) => dispatch(setRankingList({ type: '4', data: payload })),
    params: {
      type: '4',
      pageSize: 5,
      pageNo: 1
    }
  },
  {
    key: 'getDetailRanking3',
    request: fetchRankingList,
    getData: (result: any) => {
      return result.data.records.map((item: any, index: number) => ({
        ...item,
        key: index + '',
        leftContent: [item.goodsName],
        rightContent: '￥' + item.sumAmount
      }))
    },
    action: (dispatch: any, payload: any) => dispatch(setRankingList({ type: '2', data: payload })),
    params: {
      type: '2',
      pageSize: 5,
      pageNo: 1
    }
  },
  {
    key: 'getDetailRanking4',
    request: fetchRankingList,
    getData: (result: any) => {
      return result.data.records.map((item: any, index: number) => ({
        ...item,
        key: index + '',
        leftContent: [item.catalogName],
        rightContent: '￥' + item.sumAmount
      }))
    },
    action: (dispatch: any, payload: any) => dispatch(setRankingList({ type: '3', data: payload })),
    params: {
      type: '3',
      pageSize: 5,
      pageNo: 1
    }
  },
  {
    key: 'getDetailRanking5',
    request: fetchRankingList,
    getData: (result: any) => {
      return result.data.records.map((item: any, index: number) => ({
        ...item,
        key: index + '',
        leftContent: [item.supplier],
        rightContent: '￥' + item.sumAmount
      }))
    },
    action: (dispatch: any, payload: any) => dispatch(setRankingList({ type: '6', data: payload })),
    params: {
      type: '6',
      pageSize: 5,
      pageNo: 1
    }
  },
  {
    key: 'getDetailCategroy',
    request: fetchCategroy,
    getData: (result: any) => {
      return result.data
    },
    action: (dispatch: any, payload: any) => dispatch(setDetailCategory(payload)),
    params: {}
  },

  {
    key: 'getAreaList',
    request: fetchArea,
    getData: (result: any) => {
      return result.data.areaListTwoLevel
    },
    action: (dispatch: any, payload: any) => dispatch(setAreaList(payload)),
    params: {}
  }
]

export const getDetailData = createAsyncThunk<
  void,
  QueryParams,
  {
    state: RootStore
  }
>('getDetailData', async (queryParams, { dispatch, getState }) => {
  const store = getState()

  dispatch(setLoading(true))

  for await (const item of queue) {
    const params = {
      ...item.params,
      ...queryParams
    } as any
    params.marketContractHomeDTO = {
      ...params.marketContractHomeDTO,
      ...store.detail.stack.reduce((pre, cur) => ({ ...pre, [cur.type]: cur.value }), {})
    }
    store.detail.currentAreaCode && (params['areaCode'] = store.detail.currentAreaCode)
    store.detail.currentCatalogId && (params['currentCatalogId'] = store.detail.currentCatalogId)
    const result = await item.request(params)
    const data = item.getData(result)
    dispatch(item.action(dispatch, data))
    dispatch(setLoading(false))
  }
})

const config = {
  supplier: '供应商',
  areaCode: '区域',
  catalogName: '品类',
  purchaseOrgan: '采购单位',
  goodsBrand: '品牌',
  goodsName: '产品',
  marketContractId: '网超'
}

export type StackItem = {
  type: keyof typeof config
  value: string
  text: string
  tag?: string
}

const initialState: {
  stack: StackItem[]
  totalStatistics: any[]
  notice: {
    list1: any[]
    list2: any[]
    list3: any[]
  }
  marketContract: Record<string, any>
  ranking: {
    list1: any[]
    list2: any[]
    list3: any[]
    list4: any[]
    list5: any[]
  }
  category: any[]
  areaList: any[]
  currentAreaCode: string | null
  currentCatalogId: string | null
  loading: boolean
} = {
  stack: [
    // {
    //   type: 'supplier',
    //   value: '福建云鑫电子产品有限公司',
    //   text: '福建云鑫电子产品有限公司',
    //   tag: '供应商'
    // },
    // {
    //   type: 'purchaseOrgan',
    //   value: '学校',
    //   text: '学校',
    //   tag: '采购单位'
    // },
  ], // 保存当前层级的信息(面包屑)
  totalStatistics: [],
  notice: {
    list1: [], // 公告
    list2: [], // 采购意向
    list3: [] // 招标公告
  },
  marketContract: {}, // 趋势图
  ranking: {
    list1: [], // 采购单位
    list2: [], // 品牌
    list3: [], // 品类
    list4: [], // 产品
    list5: [] // 供应商
  },
  category: [], // 类别
  areaList: [], // 区域
  currentAreaCode: null, // 当前区域层级的Code
  currentCatalogId: null, // 当前类别层级的Id
  loading: false
}

const detailSlice = createSlice({
  name: 'detailSlice',
  initialState,
  reducers: {
    setNotice(state, { payload }) {
      switch (payload.type) {
        case 'htgg':
          state.notice.list1 = payload.data
          break
        case 'cgyxgg':
          state.notice.list2 = payload.data
          break
        case 'cggg':
          state.notice.list3 = payload.data
          break
      }
    },
    setMarketContract(state, { payload }) {
      state.marketContract = payload
    },
    setRankingList(state, { payload }: PayloadAction<{ type: QueryParams['type']; data: any }>) {
      switch (payload.type) {
        case '5':
          state.ranking.list1 = payload.data
          break
        case '4':
          state.ranking.list2 = payload.data
          break
        case '2':
          state.ranking.list3 = payload.data
          break
        case '3':
          state.ranking.list4 = payload.data
          break
        case '6':
          state.ranking.list5 = payload.data
          break
      }
    },
    setStack(state, { payload }: PayloadAction<StackItem>) {
      const item = {
        ...payload,
        tag: config[payload.type]
      }
      if (item.type === 'areaCode') {
        state.currentAreaCode = item.value
      }
      if (item.type === 'catalogName') {
        state.currentCatalogId = item.value
      }
      const index = state.stack.findIndex((p) => p.type == item.type)
      if (index >= 0) {
        state.stack.splice(index, 1)
      }
      state.stack.push(item)
      localStorage.setItem('stack', JSON.stringify(state.stack))
    },
    removeStatck(state, { payload }: PayloadAction<number>) {
      const index = payload + 1
      state.currentAreaCode = null
      state.currentCatalogId = null
      if (index === state.stack.length) return
      const newStack = state.stack.slice(0, index)
      // 如果stack这种还存在 地区和品类 就赋值最后一个的地区和品类
      newStack.forEach((item) => {
        if (item.type === 'areaCode') state.currentAreaCode = item.value
        if (item.type === 'catalogName') state.currentCatalogId = item.value
      })
      state.stack = newStack
      localStorage.setItem('stack', JSON.stringify(newStack))
    },
    setTotalStatistics(state, { payload }) {
      state.totalStatistics = payload
    },
    setDetailCategory(state, { payload }) {
      state.category = payload
    },
    setAreaList(state, { payload }) {
      state.areaList = payload
    },
    setupStack(state) {
      const stack = localStorage.getItem('stack')
      const tempStack = stack
        ? (JSON.parse(stack) as any[])
        : state.stack.length > 0
        ? state.stack
        : []
      state.stack = tempStack

      let currentAreaCode = null
      let currentCatalogId = null

      for (const item of tempStack) {
        if (item.type == 'catalogName') {
          currentCatalogId = item.value
        }
        if (item.type == 'areaCode') {
          currentAreaCode = item.value
        }
      }

      state.currentAreaCode = currentAreaCode
      state.currentCatalogId = currentCatalogId
    },
    clearStack(state) {
      state.stack = []
      state.currentAreaCode = null
      state.currentCatalogId = null
      localStorage.removeItem('stack')
    },
    setLoading(state, { payload }) {
      state.loading = payload
    }
  }
})

export const {
  setNotice,
  setMarketContract,
  setRankingList,
  setStack,
  setTotalStatistics,
  setDetailCategory,
  setAreaList,
  removeStatck,
  setupStack,
  clearStack,
  setLoading
} = detailSlice.actions
export default detailSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const dataReportListSlice = createSlice({
  name: 'dataReportSlice',
  initialState,
  reducers: {}
})

export default dataReportListSlice.reducer

import request from '../index'

enum API {
  area = '/bigdataapi/area/getListTwoLevel' // 获取地区
}

/**
 * 获取地区
 * @returns
 */
export const fetchArea = (queryParams?: Record<string, any>) => {
  return request.getAction({
    url: API.area,
    params: { ...queryParams }
  })
}
